import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Loader from "../components/commons/Loader";

const DashboardAdminCredit = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminCredit")
);
const DashboardAdminEducationCenter = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminEducationCenter")
);
const DashboardAdminInvoice = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminInvoice")
);
const DashboardAdminLayout = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminLayout")
);
const AdminDataPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminMain/AdminDataPage")
);
const LeadMatchDataPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminMain/LeadMatchDataPage")
);
const ProfessionalDataPage = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminMain/ProfessionalDataPage"
  )
);
const AllMatchesDetailPage = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminMatches/AllMatchesDetailPage"
  )
);
const AllMatchesPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminMatches/AllMatchesPage")
);
const UnMatchedDetailPage = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminMatches/UnMatchedDetailPage"
  )
);
const UnmatchedPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminMatches/UnmatchedPage")
);
const AdminSettingsLayout = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminSettings/AdminSettingsLayout"
  )
);
const LeadInquiriesPage = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminSupport/LeadInquiriesPage"
  )
);
const ProfessionalInquiriesPage = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminSupport/ProfessionalInquiriesPage"
  )
);
const AdminEmailTemplateEditor = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminTools/AdminEmailTemplateEditor"
  )
);
const DivisionsToolPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminTools/DivisionsToolPage")
);
const EmailTemplatesPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminTools/EmailTemplatesPage")
);
const GlobalAlertsPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminTools/GlobalAlertsPage")
);
const ProfessionsToolPage = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminTools/ProfessionsToolPage"
  )
);
const ServicesToolPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminTools/ServicesToolPage")
);
const LeadsPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminUser/LeadsPage")
);
const ProfessionalsDetailPage = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/ProfessionalsDetailPage"
  )
);
const ProfessionalsPage = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminUser/ProfessionalsPage")
);
const AdminProfileOptimization = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminSettings/AdminProfileOptimization"
  )
);
const AdminCalendar = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminSettings/AdminCalendar")
);
const AdminTeamGroup = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminSettings/AdminTeamGroup")
);
const AdminTeamMember = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminSettings/AdminTeamMember")
);
const AdminNotifications = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminSettings/AdminNotifications"
  )
);
const AdminChangePassword = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminSettings/AdminChangePassword"
  )
);
const AdminSecurity = lazy(() =>
  import("../components/admin/dashboard/dashboardAdminSettings/AdminSecurity")
);
const ProfessionalAdminSettingsLayout = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalAdminSettingsLayout"
  )
);
const ProfessionalUserProfileSettings = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalUserProfileSettings"
  )
);
const ProfessionalCalendarIntegration = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalCalendarIntegration"
  )
);
const ProfessionalCreditLimit = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalCreditLimit"
  )
);
const ProfessionalRegion = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalRegion"
  )
);
const ProfessionalSmsTemplate = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalSmsTemplate"
  )
);
const ProfessionalEmailTemplate = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalEmailTemplate"
  )
);
const ProfessionalBillingPayment = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalBillingPayment"
  )
);
const ProfessionalSettingsReviews = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalSettingsReviews"
  )
);
const PendingMatchesPage = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminMatches/PendingMatchesPage"
  )
);
const PendingMatchesDetailPage = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminMatches/PendingMatchesDetailPage"
  )
);
const ProfessionalEmailTemplateEditor = lazy(() =>
  import(
    "../components/admin/dashboard/dashboardAdminUser/dashboardProfessionalSettings/ProfessionalEmailTemplateEditor"
  )
);

const AdminRoutes = () => {
  const isMobile =
    window.matchMedia &&
    window.matchMedia("only screen and (max-width: 767px)").matches;

  return [
    <Route element={<PrivateRoute admin={true} />}>
      <Route
        path="/admin-dashboard"
        element={
          <Suspense fallback={<Loader isLoading={true} />}>
            <DashboardAdminLayout />
          </Suspense>
        }
      >
        <Route
          index
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <AdminDataPage />
            </Suspense>
          }
        />
        <Route
          path="professional-data"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalDataPage />
            </Suspense>
          }
        />
        <Route
          path="match-data"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <LeadMatchDataPage />
            </Suspense>
          }
        />

        <Route path="users">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <ProfessionalsPage />
              </Suspense>
            }
          />
          <Route
            path=":id"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <ProfessionalsDetailPage />
              </Suspense>
            }
          />
          <Route
            path="leads"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <LeadsPage />
              </Suspense>
            }
          />
        </Route>

        <Route path="matches">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AllMatchesPage />
              </Suspense>
            }
          />
          <Route
            path=":id"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AllMatchesDetailPage />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="professional-profile"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalAdminSettingsLayout title={"User Profile"}>
                <ProfessionalUserProfileSettings />
              </ProfessionalAdminSettingsLayout>
            </Suspense>
          }
        />
        <Route
          path="professional-profile/reviews"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalAdminSettingsLayout title={"Reviews"}>
                <ProfessionalSettingsReviews isMobile={isMobile} />
              </ProfessionalAdminSettingsLayout>
            </Suspense>
          }
        />
        <Route
          path="professional-profile/calendar-integration"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalAdminSettingsLayout title={"Calendar Integration"}>
                <ProfessionalCalendarIntegration isMobile={isMobile} />
              </ProfessionalAdminSettingsLayout>
            </Suspense>
          }
        />

        <Route
          path="professional-profile/credit-limit"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalAdminSettingsLayout title={"Credit limit"}>
                <ProfessionalCreditLimit isMobile={isMobile} />
              </ProfessionalAdminSettingsLayout>
            </Suspense>
          }
        />
        <Route
          path="professional-profile/region"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalAdminSettingsLayout title={"Region"}>
                <ProfessionalRegion isMobile={isMobile} />
              </ProfessionalAdminSettingsLayout>
            </Suspense>
          }
        />

        <Route
          path="professional-profile/sms-template"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalAdminSettingsLayout title={"Sms template"}>
                <ProfessionalSmsTemplate isMobile={isMobile} />
              </ProfessionalAdminSettingsLayout>
            </Suspense>
          }
        />
        <Route
          path="professional-profile/email-template/:title"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalEmailTemplateEditor />
            </Suspense>
          }
        />
        <Route
          path="professional-profile/email-template"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalAdminSettingsLayout title={"Email template"}>
                <ProfessionalEmailTemplate isMobile={isMobile} />
              </ProfessionalAdminSettingsLayout>
            </Suspense>
          }
        />

        <Route
          path="professional-profile/billing-payment"
          element={
            <Suspense fallback={<Loader isLoading={true} />}>
              <ProfessionalAdminSettingsLayout title={"Billing & Invoices"}>
                <ProfessionalBillingPayment isMobile={isMobile} />
              </ProfessionalAdminSettingsLayout>
            </Suspense>
          }
        />

        <Route path="unmatched">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <UnmatchedPage />
              </Suspense>
            }
          />
          <Route
            path=":id"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <UnMatchedDetailPage />
              </Suspense>
            }
          />
        </Route>

        <Route path="pending">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <PendingMatchesPage />
              </Suspense>
            }
          />
          <Route
            path=":id"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <PendingMatchesDetailPage />
              </Suspense>
            }
          />
        </Route>

        <Route path="credit-requests">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <DashboardAdminCredit />
              </Suspense>
            }
          />
        </Route>

        <Route path="invoices">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <DashboardAdminInvoice />
              </Suspense>
            }
          />
        </Route>

        <Route path="admin-tools">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <ProfessionsToolPage />
              </Suspense>
            }
          />
          <Route
            path=":profession_id"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <ServicesToolPage />
              </Suspense>
            }
          />
          <Route
            path=":profession_id/:division_id"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <DivisionsToolPage />
              </Suspense>
            }
          />
          <Route
            path="email-template/:title"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AdminEmailTemplateEditor />
              </Suspense>
            }
          />
          <Route
            path="email-templates"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <EmailTemplatesPage />
              </Suspense>
            }
          />
          <Route
            path="global-alerts"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <GlobalAlertsPage />
              </Suspense>
            }
          />
        </Route>

        <Route path="education-center">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <DashboardAdminEducationCenter />
              </Suspense>
            }
          />
        </Route>

        <Route path="support">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <ProfessionalInquiriesPage />
              </Suspense>
            }
          />
          <Route
            path="lead-inquiries"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <LeadInquiriesPage />
              </Suspense>
            }
          />
        </Route>

        <Route path="settings">
          <Route
            index
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AdminSettingsLayout title={"Profile Optimization"}>
                  <AdminProfileOptimization isMobile={isMobile} />
                </AdminSettingsLayout>
              </Suspense>
            }
          />
          <Route
            path="calendar"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AdminSettingsLayout title={"Calendar"}>
                  <AdminCalendar isMobile={isMobile} />
                </AdminSettingsLayout>
              </Suspense>
            }
          />
          <Route
            path="team-group"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AdminSettingsLayout title={"Team Group"}>
                  <AdminTeamGroup isMobile={isMobile} />
                </AdminSettingsLayout>
              </Suspense>
            }
          />
          <Route
            path="team-member"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AdminSettingsLayout title={"Team Member"}>
                  <AdminTeamMember isMobile={isMobile} />
                </AdminSettingsLayout>
              </Suspense>
            }
          />
          <Route
            path="notification-settings"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AdminSettingsLayout title={"Notifications"}>
                  <AdminNotifications isMobile={isMobile} />
                </AdminSettingsLayout>
              </Suspense>
            }
          />
          <Route
            path="password-settings"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AdminSettingsLayout title={"Change Password"}>
                  <AdminChangePassword isMobile={isMobile} />
                </AdminSettingsLayout>
              </Suspense>
            }
          />
          <Route
            path="security-settings"
            element={
              <Suspense fallback={<Loader isLoading={true} />}>
                <AdminSettingsLayout title={"Active Sessions"}>
                  <AdminSecurity isMobile={isMobile} />
                </AdminSettingsLayout>
              </Suspense>
            }
          />
        </Route>
      </Route>
    </Route>,
  ];
};

export default AdminRoutes;
